.layer-group-button {
  position: relative;
}

.tooltip-content {
  opacity: 0;
  visibility: hidden;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  color: #fff;
  padding: 5px;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.layer-group-button:hover .tooltip-content {
  opacity: 1;
  visibility: visible;
}