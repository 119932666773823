@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .fade-in {
    animation: fadeIn 0.3s forwards;
  }
  
  .fade-out {
    animation: fadeOut 0.3s forwards;
  }
  
  @keyframes moveLeft {
    from {
      transform: translateX(54px);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes moveRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(54px);
    }
  }
  
  .move-left {
    animation: moveLeft 0.3s forwards;
  }
  
  .move-right {
    animation: moveRight 0.3s forwards;
  }
  
  .opacity-show {
    opacity: 1;
  }
  
  .opacity-hide {
    opacity: 0;
  }
  