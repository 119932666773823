.gas-fee-container {
    border: solid 1.5px #071D6C;
    border-radius: 24.7px 0 24.5px 0;
    box-shadow: 4px 4px 30px -10px rgba(255, 255, 255, 0.4);
    width: 300px;
    height: 216px;
    background-color: #12171D;
}

.corner-image {
    position: absolute;
    top: -15px;
    left: 0;
    transform: translateX(-40%);
}

.ellipse1-image {
    position: absolute;
    top: 24px;
    left: 55px;
    width: 180px;
    height: 120px;
}

.ellipse2-image {
    position: absolute;
    top: 24px;
    left: 55px;
    width: 90px;
    height: 120px;
}

.union-image {
    width: 14px;
    height: 49px;
    position: absolute;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
}