.swapchat-view-container {
    position: relative;
    border: solid 2px #292929;
    border-radius: 0px 0px 10px 10px;
    background-color: #0A0A0A;
    height: 100%;
    /* box-shadow: -3px 4px 30px -10px rgba(255, 255, 255, 0.4); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 355px;
}

.swapchat-items-list{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    max-height: calc(78vh - 100px); /* Adjust based on the height of the search bar and margins */
    scrollbar-width: hidden; /* For Firefox */
}

.swapchat-items-list::-webkit-scrollbar {
    width: 0px; /* For Webkit browsers */
}

.swapchat-items-list::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.swapchat-items-list > div {
    flex: 0 0 auto; /* Prevent shrinking and stretching */
}
