.token-card-container {
    display: flex;
    overflow: hidden;
    background-color: transparent;
    color: white;
}

.token-card-image img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border: solid 1px #41A0E6;
    border-radius: 6px;
}

.token-card-description {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.token-card-description .name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #D9D9D9;
}

.token-card-description .description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #D9D9D9;
}

.social-links {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.social-icon {
    margin-right: 10px;
    font-size: 1.2em;
    color: #B9BCBF;
}

.social-icon:hover {
    color: white;
    cursor: pointer;
}

.data-item {
    margin-bottom: 10px;
}