@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Neue+Haas+Grotesk+Display+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-zendots {
  font-family: "montserrat";
}

.font-worksans {
  font-family: "Work Sans";
}

.font-inter {
  font-family: "Inter";
}

.font-neus {
  font-family: "Neue Haas Grotesk Display Pro";
}

.page-title-gradient {
  background: linear-gradient(to right, #0385FF, #04CCE9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.item-title-gradient {
  background: linear-gradient(to right, #48B2FF, #0085FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
