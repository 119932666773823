.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tab-button {
  color: #B2B9C1;
  background-color: #131F34;
  padding-top: 16px;
  box-sizing: border-box;
  line-height: normal;
  display: inline-block;
  height: 50px;
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.tab-button-selected {
  color: transparent;
  background-image: linear-gradient(to right, #0085FF, #04CCE9);
  background-clip: text;
  padding-top: 12px;
  -webkit-background-clip: text;
  border: solid 2px #0074C8;
  border-bottom: none;
  box-sizing: border-box;
  line-height: normal;
  display: inline-block;
  transform: scaleY(1.2);
  transform-origin: bottom;
  transition: transform 0.3s ease;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}