.buy-tab {
    border: solid 2px #292929;
    border-radius: 8px 20px 0 0;
    background-color: #121212;
    color:white;
}

.sell-tab {
    border: solid 2px #292929;
    border-radius: 0 0 8px 20px;
    background-color: #121212;
    color:white;
}

.buy-tab-selected {
    border: solid 2px #0385FF;
    border-radius: 8px 20px 0 0;
    background-color: #0385FF;
    color: black;
}

.sell-tab-selected {
    border: solid 2px #0385FF;
    border-radius: 0 0 8px 20px;
    background-color: #0385FF;
    color: black;
}