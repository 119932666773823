.nomatch-container {
    padding: 20px;
    text-align: center;
}

.nomatch-container h1 {
    font-size: 2.5em;
    color: #ff0000;
}

.nomatch-container p {
    font-size: 1.2em;
    color: #666;
}

.back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.back-home:hover {
    background-color: #0056b3;
}
