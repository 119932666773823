.token-card-list {
    border: solid 2px #071D6C;
    /* border-radius: 50px 0 10px 0; */
    box-shadow: 2px 4px 30px -10px rgba(255, 255, 255, 0.4);
    background-color: #12171D;
    display: flex;
    flex-direction: column;
    height: 80vh;
    overflow-y: scroll;
    max-height: calc(100vh - 100px); /* Adjust based on the height of the search bar and margins */
    scrollbar-width: hidden; /* For Firefox */
}

.token-card-list::-webkit-scrollbar {
    width: 0px; /* For Webkit browsers */
}

.token-card-list::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.token-card-list > div {
    flex: 0 0 auto; /* Prevent shrinking and stretching */
}

.chat-view-container {
    position: relative;
    border: solid 2px #071D6C;
    border-radius: 0 50px 0 5px;
    background-color: #12171D;
    height: 87vh;
    box-shadow: -3px 4px 30px -10px rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-items-list{
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: scroll;
    max-height: calc(85vh - 100px); /* Adjust based on the height of the search bar and margins */
    scrollbar-width: hidden; /* For Firefox */
}

.chat-items-list::-webkit-scrollbar {
    width: 0px; /* For Webkit browsers */
}

.chat-items-list::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.chat-items-list > div {
    flex: 0 0 auto; /* Prevent shrinking and stretching */
}

.chat-title {
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #12171D;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 48px;
    color: white;
    font-weight: 400;
    font-family: "Zen Dots";
    font-size: 20px;
    border: solid 1px #48B2FF;
    border-radius: 10px 0 10px 0;
}