.heart-icon {
    position: absolute;
    transition: transform 0.5s ease, color 0.5s ease;
}

.fade-in {
    opacity: 1;
    z-index: 1;
}

.fade-out {
    opacity: 0;
    z-index: 0;
}

.heart-icon:hover {
    transform: scale(1.1);
}